import React from 'react'
import LandingPage from 'components/Landing'
import SEO from 'components/seo'

const Home = ({location}) => {
  return (
    <>
      <SEO title="Home" />   
      <LandingPage location={location}/>
   </>
  )
}

export default Home
