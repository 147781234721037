import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from 'react-spring'

const FadeInTop = ({ children }) => {
  const springProps = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)', willChange: 'transform, opacity' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)',  willChange: 'transform, opacity' }
  })
  return <animated.div style={springProps}>{children}</animated.div>
}

export default FadeInTop

FadeInTop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired
}
