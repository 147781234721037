import styled from 'styled-components'

export const LandingWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
`
export const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height:300px;
`
