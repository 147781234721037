import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from 'react-spring'

const FadeInBottom = ({ children }) => {
  const springProps = useSpring({
    config: config.slow,
    to: {opacity: 1, transform: 'translate3d(0, 0, 0)'},
    from: { opacity: 0, transform: 'translate3d(0, 30px, 0)'},
  })
  return <animated.div style={springProps}>{children}</animated.div>
}

export default FadeInBottom

FadeInBottom.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired
}

