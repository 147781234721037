import React from 'react'
import { Link } from 'gatsby'

import { LandingWrapper, LandingContainer } from './styles'
import FadeInBottom from 'elements/Animations/FadeInBottom'
import FadeInTop from 'elements/Animations/FadeInTop'
import Logo from 'elements/Logo'
import Navigation from 'elements/Navigation'
// import Languages from '../../elements/Languages'


const LandingPage = () => {
  return (
    <LandingWrapper>
      <LandingContainer>
        <FadeInTop>
          <Link to="/">
            <Logo />
          </Link>
        </FadeInTop>
        <FadeInBottom>
          <Navigation topMargin="true" className="landing-mode" />
        </FadeInBottom>
      </LandingContainer>
      {/* <Languages /> */}
    </LandingWrapper>)
      
}

export default LandingPage
